






















































import { Vue, Prop, Component } from "vue-property-decorator"
import Agent from "~/models/Agent"
import OcularAvatar from "~/components/OcularAvatar.vue"
import OcularPhone from "~/components/OcularPhone.vue"
import OcularRatingAdvise from "~/components/OcularRating/OcularRatingAdvise.vue"

@Component({
  components: { OcularRatingAdvise, OcularAvatar, OcularPhone },
})
export default class AgentInfos extends Vue {
  @Prop({ required: true }) agent!: Agent
  @Prop({ default: false }) displayPhone!: boolean
  @Prop({ default: false }) displayAgency!: boolean
  @Prop({ default: null }) pictureSize!: null | "medium" | "small"
  @Prop({ default: true }) displayPicture!: boolean
  @Prop({ default: false }) displayRating!: boolean
  @Prop({ default: null }) hasVerified!: boolean
  @Prop({ required: false }) pictureColor!: string
  @Prop({ default: false }) phoneHighlight!: boolean
}
