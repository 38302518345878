









































































































































import { Component, Vue, Prop } from "vue-property-decorator"
import Agent from "~/models/Agent"
import OcularAvatar from "~/components/OcularAvatar.vue"
import OcularRatingAdvise from "~/components/OcularRating/OcularRatingAdvise.vue"
import OcularPhone from "~/components/OcularPhone.vue"
import snackbar from "~/plugins/snackbar"
import CreateAgent from "@/components/modals/CreateAgent.vue"
import AgentInfos from "@/components/agents/AgentInfos.vue"

@Component({
  components: {
    AgentInfos,
    OcularAvatar,
    OcularRatingAdvise,
    OcularPhone,
    CreateAgent,
    OcularPrompt: () => import("~/components/OcularPrompt.vue"),
  },
})
export default class AgentCard extends Vue {
  @Prop({ required: true }) agent!: Agent
  @Prop({ default: "list" }) display!: "full" | "list" | "small"
  @Prop({ default: 2 }) elevation!: number
  @Prop({ default: null }) onClick!: ((agent: Agent) => any) | null
  updatedAgent = new Agent().assign(this.agent)
  loadEditor = false

  onUpdateAgent() {
    this.agent.assign(this.updatedAgent)
    snackbar({ color: "success", content: `Agent mis à jour 👌` })
  }
  openEditor() {
    if (this.loadEditor) this.agentEditorLoaded()
    else this.loadEditor = true
  }
  agentEditorLoaded() {
    const editor = this.$refs.updateAgentDialog as CreateAgent
    editor.open()
  }
}
