















import { Component, Vue, Prop } from "vue-property-decorator"

@Component({})
export default class OcularAvatar extends Vue {
  @Prop({ default: null }) image!: string
  @Prop({ default: "" }) name!: string
  @Prop({ default: false }) pointer!: boolean
  @Prop({ default: 51 }) size!: number
  @Prop({ default: false }) small!: boolean
  @Prop({ default: false }) medium!: boolean
  @Prop({ default: "primary" }) color!: string
  @Prop({ required: false, default: false }) isIndividual!: boolean
}
