






import { Component, Vue, Prop } from "vue-property-decorator"

@Component({})
export default class OcularPhone extends Vue {
  @Prop({ default: "" }) phonenumber!: string
}
