



















































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import OcularDialog from "~/components/OcularDialog.vue"
import Agent from "~/models/Agent"
import Agency from "~/models/Agency"
import {
  agencies,
  agents,
  Hit,
  Agency as AlgoliaAgency,
} from "~/config/algolia"
import { enums } from "@/store"
import { isValidEmail, isValidPhoneNumber } from "~/utils/validate"
import snackbar from "~/plugins/snackbar"

@Component({
  components: {
    OcularDialog,
  },
})
export default class CreateAgent extends Vue {
  @Prop({ default: null }) agency!: null | Agency // use this prop to lock an agency
  @Prop({ default: null }) onValidate!: null | ((agent: Agent) => void)
  @Prop({ default: () => new Agent() }) agent!: Agent
  isMobileNumberAvailable = true
  searchAgencies: null | string = null
  algoliaAgencies: Hit<AlgoliaAgency>[] = []
  loading = false

  mounted() {
    if (this.agency) this.agent.agency_id = this.agency.id
    else {
      this.findAlgoliaAgencies(this.agent.agency_name || "")
    }
    this.$emit("init")
  }
  get dialog() {
    return this.$refs.dialog as OcularDialog
  }
  get areas() {
    return Object.entries(enums("zipcodes"))
  }
  get jobItems() {
    return [
      { value: "director", text: "Directeur" },
      { value: "agent", text: "Agent" },
    ]
  }
  get mobileNumberValidation() {
    return [
      this.notEmpty,
      this.phoneNumber,
      this.isMobileNumberAvailable ? true : "Numéro non disponible",
    ]
  }

  open() {
    this.dialog.open = true
  }
  uploadAgentAvatar(event: Event) {
    const file = (event.target as any).files[0]
    if (file) {
      const fileReader = new FileReader()
      fileReader.onloadend = ({ target }) =>
        (this.agent.image = String(target?.result))
      fileReader.readAsDataURL(file)
    } else this.agent.image = null
  }
  async saveAgent() {
    this.loading = true
    await this.agent.saveAndAssign()
    if (this.onValidate) this.onValidate(this.agent)
    else {
      snackbar({
        color: "success",
        content: `L'agent a été ${this.agent.id ? "mis à jour" : "créé"} 👌`,
      })
      this.$emit("created", this.agent)
    }
    this.loading = false
    this.dialog.close()
  }
  async close() {
    this.dialog.close()
  }

  @Watch("searchAgencies")
  @Watch("agent.agency_id")
  async findAlgoliaAgencies(value = "") {
    const response = await agencies.search(value)
    this.algoliaAgencies = response.hits as Hit<AlgoliaAgency>[]
  }

  @Watch("agent.mobile_number")
  async isMobileNumberAvailableOnAlgolia(value: string) {
    const formattedPhoneNumber = this.$options.filters?.formatPhoneNumber(value)
    if (isValidPhoneNumber(formattedPhoneNumber)) {
      const response = await agents.search(formattedPhoneNumber)
      this.isMobileNumberAvailable =
        response.hits.length == 0 ||
        (response.hits[0] as Hit<Agent>)["id"] == this.agent.id
    }
  }

  // -- rules
  notEmpty = (value: string | number | Array<any> | null | undefined) =>
    typeof value == "number"
      ? true
      : !!(value && value.length) || "Champ requis"
  email = (value: string) => !value || isValidEmail(value) || "Email incorrect"
  phoneNumber = (value: string) =>
    !value || isValidPhoneNumber(value) || "Numéro incorrect"
}
